import React, { useState, useEffect, useContext, useRef } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';
import { Context } from 'store/createContext';
import MEDIA from 'helpers/mediaTemplates';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Banner from 'components/banner';

import Ad from 'components/ad';
import { almostBlack, lightGrey, brNorm, rhythm, theme } from 'constants/theme';

const Button = ({ children, onClick }) => {
  return (
    <button className="add-to-calendar-button" onClick={onClick}>
      {children}
    </button>
  );
};

const Dropdown = ({ children }) => {
  return <div className="add-to-calendar-dropdown">{children}</div>;
};

const StyledBackground = styled(BackgroundImage)`
  &::before,
  &::after {
    filter: brightness(
      ${({ isDarken }) => {
        return isDarken ? '50%' : '100%';
      }}
    );
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ResiReplayInfo = styled.div`
  padding: ${rhythm};
  border-radius: 0 0 ${brNorm} ${brNorm};
  background-color: ${almostBlack};
  color: ${lightGrey};
  text-align: center;
  line-height: 1.4;

  a {
    color: white;
    text-decoration: underline;
  }

  strong {
    color: white;
  }
`;

const StyledResiContainer = styled.div`
  width: 100%;
  margin: 4rem 0 6rem;
  padding: 0 4rem;

  .resi--container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    border-radius: ${brNorm} ${brNorm} 0 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const StyledAdsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
  margin-top: 1.25rem;
  margin: 1.25rem 2rem 0 2rem;

  ${MEDIA.DESKTOP`
    grid-template-columns: 100%;
  `};

  ${MEDIA.TABLET`
    margin: 2rem 0rem;
  `};
`;

const StyledUpgradeCTA = styled.div`
  width: 100%;

  button {
    margin: auto;
  }
`;

const Index = ({ data, location }) => {
  //
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  dayjs.extend(dayjsPluginUTC);
  const { events } = data.allEventsJson.nodes[0];

  // Component State
  // const [showVideo, setShowVideo] = useState(false);
  const [theEvent, setTheEvent] = useState(events[0]);
  const [bannerMessage, setBannerMessage] = useState('');

  const store = useContext(Context);
  const vipRef = useRef(null);

  const { eventTiming, setGlobalEventTiming } = store;

  useEffect(() => {
    (async function init() {
      getVip();
      getRsvp();
      await checkFeatureToggles();
      websocketConnect();

      // loadHubspotForm();
    })();

    checkEventStatus();
    // check for event status every 60 seconds
    const interval = setInterval(() => {
      checkEventStatus();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getVip = () => {
    const params = new URLSearchParams(location.search);
    const vipParam = params.get('vip');

    store.setVip(vipParam);

    if (vipParam === 'cbvip2024') {
      store.setVerifiedVip('verified');
      vipRef.current = 'verified';
    }
  };

  const getRsvp = () => {
    const params = new URLSearchParams(location.search);
    const rsvpParam = params.get('rsvp');

    store.setRsvp(rsvpParam);

    if (rsvpParam === 'cb2024') {
      store.setVerified('verified');
    }
  };

  const websocketConnect = () => {
    const heartbeat = ws => {
      ws.send('__beat__');
    };

    let ws;
    const connect = () => {
      let interval;

      ws = new WebSocket(
        'wss://stag.ws.businessmadesimple.com/app/bms-mastery'
        // 'wss://prod.ws.businessmadesimple.com/app/bms-mastery'
      );

      // Setup our heartbeat to keep connection open
      ws.onopen = () => {
        console.log('Socket connected.');
        interval = setInterval(() => {
          heartbeat(ws);
        }, 30000);

        ws.send(
          JSON.stringify({
            event: 'pusher:subscribe',
            data: { auth: '', channel: 'mastery-api' },
          })
        );
      };

      // If heartbeat, ignore
      // Otherwise, update our store
      ws.onmessage = msg => {
        if (msg.data === '__alive__') return;
        const { data, event } = JSON.parse(msg.data);

        const _data = data ? JSON.parse(data) : null;

        if (event.includes('features.update')) {
          // if (event.includes('ToggleFeatureEvent')) {
          const toggle = _data.toggles.find(t => t.feature === 'cb_gen_on');
          if (toggle) {
            store.setShowVideo(toggle.on);
          }

          const vip = _data.toggles.find(t => t.feature === 'cb_vip_on');

          if (vip && vipRef && vipRef.current === 'verified') {
            store.setShowVideo(vip.on);
          }

          const banner = _data.toggles.find(t => t.feature === 'cb_banner');
          if (banner?.on) {
            setBannerMessage(banner.additional);
          } else {
            setBannerMessage('');
          }
        }
      };

      // Clear our heartbeat if websocket closes unexpectedly
      ws.onclose = () => {
        ws = null;
        clearInterval(interval);
        console.log('Socket connection has closed.');
      };

      ws.onerror = error => {
        console.log('Socket error: ', { error });
      };
    };

    const reconnect = async () => {
      try {
        await connect();
      } catch (error) {
        console.log('Error reconnecting to websocket: Error ', { error });
      }
    };

    // Initial socket connection
    try {
      connect();
    } catch (error) {
      console.log('Socket error connecting.', error);
    }

    // Reconnect to socket if we lose connection
    setInterval(() => {
      if (!ws) {
        reconnect();
      }
    }, 5000);
  };

  const checkFeatureToggles = () => {
    return axios
      .get(process.env.GATSBY_MASTERY_API)
      .then(function(response) {
        // handle success
        if (response.data) {
          const toggle = response.data.find(f => f.feature === 'cb_gen_on');
          if (toggle) {
            store.setShowVideo(toggle.on);
          }

          const vip = response.data.find(t => t.feature === 'cb_vip_on');

          if (vip && vipRef && vipRef.current === 'verified') {
            store.setShowVideo(vip.on);
          }

          const banner = response.data.find(t => t.feature === 'cb_banner');

          if (banner?.on) {
            setBannerMessage(banner.additional);
          } else {
            setBannerMessage('');
          }
        }
      })
      .catch(function(error) {
        // handle error
        // console.log(error);
      });
  };

  const checkEventStatus = async () => {
    // Get event to display
    const now = dayjs.utc();

    // if event is recently ended
    const recentlyEndedEvents = events.filter(event => {
      const end = dayjs.utc(event.end_date_utc);
      const diff = end.diff(now, 'minute');

      const endTime = 10;

      return diff < -endTime && diff > -60 - endTime;
    });

    // console.log({ currentlyHappening, recentlyEndedEvents });

    // any events in the future
    const upcomingEvents = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc);
      const diff = start.diff(now, 'minute');
      return diff > 0;
    });

    ////
    //
    // set current state of events based on above
    //
    ////

    if (upcomingEvents.length && !recentlyEndedEvents.length) {
      if (eventTiming !== 'upcoming') {
        setGlobalEventTiming('upcoming');
      }
      setTheEvent(upcomingEvents[0]);
    }

    if (recentlyEndedEvents.length) {
      if (eventTiming !== 'over') {
        setGlobalEventTiming('over');
      }
      setTheEvent(recentlyEndedEvents[0]);
    }
  };

  ////
  //
  // misc
  //
  ////

  const StartDateInCentralTime = dayjs(theEvent.start_date_central);

  // Choosing the ad
  const { ads } = data.allAdsJson.nodes[0];

  // Calendar Event
  const calendarEvent = event => {
    // let vip = store ? store.vip : '';
    let rsvp = store ? store.rsvp : '';
    let vip = store ? store.vip : '';
    let url = '';

    if (store.verifiedRsvp === 'verified') {
      url = 'https://livestream.coachbuilder.com/?rsvp=' + rsvp;
    }

    if (store.verifiedVip === 'verified') {
      url = 'https://livestream.coachbuilder.com/?vip=' + vip;
    }

    let eventLength = event.description.indexOf('day 2') > -1 ? 0 : 1;
    return {
      description: event.description,
      duration: 7,
      endDatetime: dayjs(theEvent.end_date_central)
        .add(eventLength, 'day')
        .format('YYYYMMDDTHHmmss'),
      location: url,
      startDatetime: dayjs(theEvent.start_date_central).format(
        'YYYYMMDDTHHmmss'
      ),
      title: 'The Coach Builder Summit',
      timezone: 'America/Chicago',
    };
  };

  return (
    <Layout bannerMessage={bannerMessage}>
      <Box>
        <Title as="h2" size="large">
          {store.verifiedVip === 'verified' ? (
            <>
              The Coach Builder Summit: <span className="text-theme">VIP</span>
            </>
          ) : (
            <>The Coach Builder Summit</>
          )}
        </Title>
        {store.showVideo ? (
          store.verifiedRsvp !== 'verified' &&
          store.verifiedVip !== 'verified' ? (
            <div style={{ maxWidth: '600px', margin: '20px auto' }}>
              <h2>
                You are not authorized to access the live stream. Please check
                that you are using the correct link to access the live stream.
              </h2>
            </div>
          ) : (
            <>
              <StyledResiContainer>
                <div className="resi--container">
                  <iframe
                    title="The Coach Builder Summit"
                    allow="autoplay; fullscreen"
                    allowFullScreen={true}
                    src={`https://control.resi.io/webplayer/video.html?id=${theEvent.embed_code}&iframe=1&autoplay=1`}
                    style={{ width: '100%' }}
                  ></iframe>
                </div>
                <ResiReplayInfo className="resi--replay-info">
                  You’ll receive a replay of the entire two-day event on
                  February 8 at the email address you used to register.
                </ResiReplayInfo>
              </StyledResiContainer>
            </>
          )
        ) : (
          <Banner>
            <StyledBackground
              isDarken={false}
              Tag="div"
              fluid={theEvent.banner_image.source.childImageSharp.fluid}
              className="banner-background"
              style={{
                backgroundPosition: 'center center',
                width: '100%',
                borderRadius: '3rem',
                overflow: 'hidden',
              }}
            >
              <div className="banner-content">
                <p className="banner-content__topic">{data.bannerJson.title}</p>
                <h3 className="banner-content__title">{theEvent.name}</h3>
                {/* {eventTiming === 'upcoming' && (
                  <p className="banner-content__about">
                    {theEvent.description}
                  </p>
                )} */}
                {eventTiming === 'upcoming' ? (
                  <>
                    <p className="banner-content__start-date">
                      {theEvent.description} {'\n'}
                      {StartDateInCentralTime.format('dddd MMMM D, YYYY')} at
                      9:00am CT
                    </p>
                  </>
                ) : (
                  <div className="banner-content__over">This event is over</div>
                )}
                {eventTiming === 'over' && (
                  <>
                    <p className="banner-content__about">
                      You’ll receive a replay of the entire two-day event on
                      February 8 at the email address you used to register.
                    </p>
                  </>
                )}
                {eventTiming === 'upcoming' &&
                (store.verifiedRsvp === 'verified' ||
                  store.verifiedVip === 'verified') ? (
                  <AddToCalendarDropdown
                    className="add-to-calendar"
                    buttonText="Add to my calendar"
                    event={calendarEvent(theEvent)}
                    items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                  />
                ) : null}
              </div>
              {eventTiming === 'upcoming' ? (
                <ResiReplayInfo className="resi--replay-info">
                  You’ll receive a replay of the entire two-day event on
                  February 8 at the email address you used to register.
                </ResiReplayInfo>
              ) : null}
            </StyledBackground>
          </Banner>
        )}

        {store.verifiedRsvp === 'verified' ? (
          <StyledAdsContainer>
            {ads
              .filter(item => item.type === 'General')
              .map(ad => (
                <Ad ad={ad} key={ad.title} />
              ))}
          </StyledAdsContainer>
        ) : null}
        {store.verifiedVip === 'verified' ? (
          <StyledAdsContainer>
            {ads
              .filter(item => item.type === 'VIP')
              .map(ad => (
                <Ad ad={ad} key={ad.title} />
              ))}
          </StyledAdsContainer>
        ) : null}
      </Box>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
    bannerJson {
      title
      image {
        alt
        source {
          childImageSharp {
            fluid(maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allEventsJson {
      nodes {
        events {
          additional
          cta
          description
          embed_code
          start_date_utc
          end_date_utc
          start_date_central
          end_date_central
          id
          name
          workbook_download
          guide_workbook_download
          execution_workbook_download
          brandscript_download
          banner_image {
            alt
            source {
              childImageSharp {
                fluid(maxHeight: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        id
      }
    }
    allAdsJson {
      nodes {
        ads {
          copy
          cta
          cta_link
          type
          newTab
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          subTitle
        }
      }
    }
    imageSharp(original: { src: { regex: "/Worksheet/" } }) {
      gatsbyImageData
      fluid(maxWidth: 30) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
